<template>
    <div></div>
</template>

<script>
export default {
  mounted: function() {
    this.showLoadingSpinner();
    this.getResponse();
  },
  methods: {
    getResponse: function () {
        const url = baseUrl + 'online-payments/last-sale-type'
        const headers = {
            'Authorization': 'Bearer ' + this.accessToken(),
        }
        axios.get(url, {
                headers: headers
            })
            .then(response => {
                const tabName = response.data.status ? response.data.sale_type : 'individual'
                this.redirect(tabName)
            })
            .catch(function () {
               this.redirect()
            })
    },
    redirect: function (tabName = 'individual') {
        this.$router.push({
            name: 'Sales',
            params: { tab_type: tabName}
        }, undefined, () => { 
            location.href = this.$route.hash 
        })
        this.hideLoadingSpinner()
    },
    showLoadingSpinner: function () {
      this.$q.loading.show()
    },
    hideLoadingSpinner: function () {
       this.$q.loading.hide()
    }
  }
}
</script>

<style></style>